<template>
  <el-dialog
    title="选择提交人"
    :visible.sync="otherAuditPersonVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <el-select style="width: 80%" clearable v-model="ruleForm.region" placeholder="请选择">
            <el-option v-for="(item, index) in configOption" :disabled="item.disabled" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
  import { selectAllRole } from "@/api/homeCasedCareServices"
  export default {
    name: "otherAuditPerson",
    props: {
      otherAuditPersonVisible: {
        type: Boolean,
        default: false
      },
      otherId: {
        type:[String, Number],
        default: ""
      },
      selectedArr: {
        type:Array,
        default:() => {
          return []
        }
      }
    },
    data() {
      return {
        configOption: [
          {
            id: "1",
            label: '社区居委会'
          },
          {
            id: "2",
            label: '街道办事处'
          },
          {
            id: "3",
            label: '街道办事处1'
          },
          {
            id: "4",
            label: '街道办事处1'
          }
        ],
        ruleForm: {
          region: ''
        },
        rules: {

        }
      }
    },
    watch: {
      otherId(val) {
        this.ruleForm.region = val
      },
      selectedArr: {
        deep:true,
        immediate:true,
        handler(val) {
          this.configOption.forEach(item => {
            if(val.includes(item.id)) {
              item.disabled = true
            }else {
              item.disabled = false
            }
          })
        }
      }
    },
    mounted() {
      this.selectAllRoleData()
    },
    methods: {
      /*获取配置*/
      async selectAllRoleData() {
        const res = await selectAllRole()
        this.configOption = res.data
      },
      handleClose() {
        this.$emit('update:otherAuditPersonVisible', false)
      },
      submit() {

        let name
        this.configOption.forEach(item => {
          if(item.id === this.ruleForm.region) {
            name = item.roleName
            item.disabled = true
          }
        })
        this.$emit('postOtherAudit', { id: this.ruleForm.region, name: name })
        this.$emit('update:otherAuditPersonVisible', false)
      }
    }
  }
</script>

<style scoped>

</style>
